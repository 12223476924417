window.addEventListener('load', function() {
    const images = document.querySelectorAll('img[data-src]');

    images.forEach(function(img) {
        const dataSrc = img.getAttribute('data-src');

        img.setAttribute('src', dataSrc);

        img.removeAttribute('data-src');

        img.addEventListener('load', function() {
            const parent = img.closest('.load');
            if (parent) {
                parent.classList.remove('load');
            }
        });
    });
});
