import Rails from "@rails/ujs";
document.addEventListener('turbo:load', function () {
  let popupOverlay  = document.querySelector('#main-popup');
  let closeBtn      = document.querySelector('#close-main-popup');
  let openPopupBtns = document.querySelectorAll('.request-btn');

  if (document.querySelector('#main-popup')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let popupOverlay  = document.querySelector('#popup-offer-request');
  let closeBtn      = document.querySelector('#close-offer-request');
  let openPopupBtns = document.querySelectorAll('.offer-request-btn');

  if (document.querySelector('#popup-offer-request')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#popup-special-offer-request');
  let closeBtn = document.querySelector('#close-special-offer-request');
  let openPopupBtns = document.querySelectorAll('.special-offer-request-btn');

  if (document.querySelector('#popup-special-offer-request')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let popupOverlay  = document.querySelector('#popup-credit-request');
  let closeBtn      = document.querySelector('#close-credit-request');
  let openPopupBtns = document.querySelectorAll('.credit-request-btn');

  if (document.querySelector('#popup-credit-request')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let popupOverlay  = document.querySelector('#popup-cant-find');
  let closeBtn      = document.querySelector('#close-cant-find');
  let openPopupBtns = document.querySelectorAll('.cant-find-btn');
  let buttonSubmit = document.querySelector('#submit-close-cant-find ')

  if (document.querySelector('#popup-cant-find')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeBtn.addEventListener('click', closePopup);

    buttonSubmit.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#popup-leasing-offer');
  let closeBtn = document.querySelector('#close-leasing-offer');
  let openPopupBtns = document.querySelectorAll('.leasing-offer-btn');

  if (document.querySelector('#popup-leasing-offer')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#another-car-popup');
  let closeBtn = document.querySelector('#close-another-car-popup');
  let openPopupBtns = document.querySelectorAll('.open-another-car-popup');

  if (document.querySelector('#another-car-popup')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

// for car show page request popup

document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#popup-leave-request');
  let closeBtn = document.querySelector('#close-leave-request');
  let openPopupBtns = document.querySelectorAll('.leave-request');

  if (document.querySelector('#popup-leave-request')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});



//==============Edit cars images popup===================

document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#popup-edit-cars-imgs');
  let closeBtn = document.querySelector('#close-popup-edit-cars-imgs');
  let openPopupBtns = document.querySelectorAll('.open-edit-cars-imgs-popup');

  if (document.querySelector('#popup-edit-cars-imgs')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let deleteButtons = document.querySelectorAll('.delete-img');

  deleteButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      let url = button.dataset.url;
      let carSlug = button.dataset.carSlug;

      Rails.ajax({
        type: "DELETE",
        url: `/live-auction/${carSlug}/delete_image`,
        data: JSON.stringify({ url: url }),
        success: (data) => {
          button.closest('.image').remove();
        },
        error: (error) => {
          console.error('Грешка при изтриване на изображението:', error);
          alert('Грешка при изтриване на изображението.');
        }
      });
    });
  });
});
