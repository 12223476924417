import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import "./controllers"

require("fslightbox");
require("./packs/fancybox");

require("./packs/admin")
require("./packs/slider")
require("./packs/header")
require("./packs/popup")
require("./packs/catalogue")
require("./packs/stage-animation.js")
require("./packs/faq")
require("./packs/pagination")
require("./packs/leasing")
require("./packs/request_form")
require("./packs/auto_open_form")
require("./packs/scroll")
require("./packs/copy")
require("./packs/phone-input")
require("./packs/search")
require("./packs/search_autocomplete")
require("./packs/lazy_load_videos")
require("./packs/green_btn_anim")
require("./packs/read_more")
require("./packs/media-load")
