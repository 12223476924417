import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['brand', 'model', 'vehicleType', 'farmEquipment', 'farmEquipmentWrapper', 'form', 'region']
  static values = { pathParam: String }

  connect() {
    this.cleanUrl();
  }

  getLocalizedPath(path) {
    const currentUrl = window.location.pathname;
    const localePrefix = currentUrl === "/en" || currentUrl.includes("/en/") ? "/en" : "";
    return `${localePrefix}${path}`;
  }

  changeRegion(event) {
    const selectedRegion = event.target.value;
    const formAction = selectedRegion === "europe" ? "/euro-catalogue" : "/catalogue";
    let vehicleType = this.vehicleTypeTargets.find(target => target.checked).value;
    let lang = this.formTarget.action.includes("/en/") ? '/en' : ''
    let pathParam = this.pathParamValue;
    this.formTarget.action = lang + formAction;

    if (selectedRegion === "europe") {
      this.farmEquipmentTarget.closest(".radio-wrapper").style.display = "none";
      this.farmEquipmentWrapperTarget.style.display = "none";

      if (this.farmEquipmentTarget.checked) {
        this.vehicleTypeTargets[0].checked = true;
        vehicleType = 'car'
      }
    } else {
      this.farmEquipmentTarget.closest(".radio-wrapper").style.display = "";
      this.farmEquipmentWrapperTarget.style.display = "";
    }

    this.fetchAndUpdate(`/change-vehicle-type-or-region?vehicle_type=${vehicleType}&region=${selectedRegion}&path=${pathParam}`);
  }

  changeVehicleType(event) {
    const vehicleType = event.target.value;
    const region = this.regionTargets.find(target => target.checked).value;
    let lang = this.formTarget.action.includes("/en/") ? '/en' : ''
    let pathParam = this.pathParamValue;
    let formAction;
    if (vehicleType === 'farm_equipment') {
      formAction = "/equipment-catalogue"
      this.fetchAndUpdate(`/change-equipment-filters?vehicle_type=${vehicleType}&region=${region}&path=${pathParam}`);
    } else {
      formAction = region === "europe" ? "/euro-catalogue" : "/catalogue";
      this.fetchAndUpdate(`/change-vehicle-type-or-region?vehicle_type=${vehicleType}&region=${region}&path=${pathParam}`);
    }
    this.formTarget.action = lang + formAction;
  }

  updateCarBrands(event) {
    const brandId = this.brandTarget.value;
    const brandModelName = event.params.brandModelName;
    const vehicleType = this.vehicleTypeTargets.find(target => target.checked).value;
    const region = this.regionTargets.find(target => target.checked).value;
    this.fetchAndUpdate(`/update-car-brands?brand_id=${brandId}&brand_model_name=${brandModelName}&vehicle_type=${vehicleType}&region=${region}`);
  }

  updateCarModels(event) {
    const brandId = this.brandTarget.value;
    const brandModelName = this.modelTarget.value;
    const vehicleType = this.vehicleTypeTargets.find(target => target.checked).value;
    const region = this.regionTargets.find(target => target.checked).value;
    this.fetchAndUpdate(`/update-car-models?brand_id=${brandId}&brand_model_name=${brandModelName}&vehicle_type=${vehicleType}&region=${region}`);
  }

  fetchAndUpdate(path) {
    let url = this.getLocalizedPath(path);
    Rails.ajax({
      type: "get",
      url: url,
      accept: 'text/vnd.turbo-stream.html',
      success: (html) => { Turbo.renderStreamMessage(html) },
      error: (error) => { console.error('Error:', error); }
    });
  }

  cleanUrl() {
    const params = new URLSearchParams(window.location.search);
    const keys = [...params.keys()];

    keys.forEach(key => {
      const value = params.get(key);
      if (value === '' || value === '0') {
        params.delete(key);
      }
    });

    const newUrl = window.location.pathname + (params.toString() ? `?${params.toString()}` : '');
    window.history.replaceState(null, '', newUrl);
  }

  getMetaContent(name) {
    return document.querySelector(`meta[name="${name}"]`).getAttribute('content');
  }

  toggleMaxHeight(event) {
    event.currentTarget.classList.toggle('expanded');
  }
}
